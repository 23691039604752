/* eslint-disable react/display-name */

import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container
} from "reactstrap"
import DatatableTables from "components/tables/DatatableTables";
import { get } from 'helpers/api_helper'
import { REPORTS_URL } from 'helpers/url_helper'
import moment from 'moment'

const Reports = () => {
    const queryParams = new URLSearchParams(location.search);
    const rt = queryParams.get('_q');

    const [data, setData] = useState([])

    const fetchData = async()=>{
        setData([])
        const res = await get(`${REPORTS_URL}?_q=${rt}`);
        if(res.success){
            setData(res.body)
        }
    }

    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true,
      },{
        dataField: 'token_no',
        text: 'Token #',
        sort: true,
        formatter: (cell, row) => <b className='text-denger'>{row.token_no}</b>
      },{
        dataField: 'name',
        text: 'Name',
        sort: true
      },{
        dataField: 'dinner_code',
        text: 'Dinner Code',
        sort: true
      },{
        dataField: 'meal_type',
        text: 'Meal Type',
        sort: true
      },{
        dataField: 'is_guest',
        text: 'Guest',
        sort: true        
      },{
        dataField: 'dine_date',
        text: 'Dine Date',
        formatter: (cell, row) => <b className='text-denger'>{row.dine_date}</b>
      },{
        dataField: 'dine_time',
        text: 'Dine Time',
        formatter: (cell, row) => <b className='text-denger'>{row.dine_time}</b>
    }]

    useEffect(() =>{
        fetchData();
    }, [rt])

    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Reports | Advance Dental Export Canteen Management</title>
                </MetaTags>
                <Container fluid>
                    {
                        Object.keys(data)?.length ? 
                        <DatatableTables
                          key={'id'}
                          title={`${capitalizeFirstLetter(rt)} Tokens Issued Report`}
                          columns={columns || []}
                          csv_filename={`${rt}_tokens_report_${moment().format('YYYY-MM-DD')}`}
                          rows={data?.dinners || []} />
                        : ''
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Reports;
