/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Modal,
  ModalBody,
  Form
} from "reactstrap"
import DatatableTables from "components/tables/DatatableTables";
import { GUEST, QR_CODE, DINER_STATUS } from 'helpers/url_helper'
import { post, get, del } from 'helpers/api_helper'
import Swal from 'sweetalert2';
import lunch from 'assets/images/lunch.png'
import dinner from 'assets/images/dinner.png'
import both from 'assets/images/both.png'

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const formObj = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  location: '',
  canteen: '',
  address: '',
  company: '',
  purpose: '',
  remark: '',
}

const Guests = () => {
  const [loading, setLoading] = useState(false);
  const [guestData, setGuestData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitBtnActive, setSubmitBtnActive] = useState(false);
  const [formData, setFormData] = useState(formObj);
  const [dinerData, setDinerData] = useState(null);

  
  const handleDineStatus = async (row, type) => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: false,
      debug: false,
      progressBar: false,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000
    };
    let a = false;

    if(type === 'lunch'){
      setGuestData(prev => prev.map((item) => item.id === row.id ? { ...item, is_lunch: !item.is_lunch } : item));
      a = row.is_lunch ? false: true;
      if(a){
        toastr.success(`${row.firstname} ${row.lastname} allowed to get lunch`);
      }else{
        toastr.error(`${row.firstname} ${row.lastname} dismissed to get lunch`);
      }
    }
    if(type === 'dinner'){
      setGuestData(prev => prev.map((item) => item.id === row.id ? { ...item, is_diner: !item.is_diner } : item));
      a = row.is_diner ? false: true;
      if(a){
        toastr.success(`${row.firstname} ${row.lastname} allowed to get dinner`);
      }else{
        toastr.error(`${row.firstname} ${row.lastname} dismissed to get dinner`);
      }
    }

    const obj = {
      diner: row.code,
      type: type,
      status: a,
      is_guest: true
    }

    const res = await post(DINER_STATUS, obj);
  }
  
  const columns = [{
    dataField: 'sr',
    text: '#',
    sort: true,
  }, {
    dataField: 'firstname',
    text: 'Name',
    sort: true,
    formatter: (cell, row) => `${row.firstname} ${row.lastname}`
  }, {
    dataField: 'code',
    text: 'Code',
    sort: true
  },{
    dataField: 'actions2',
    text: 'Lunch',
    sort: true,
    formatter: (cell, row) => {
      return (
        <div className="form-check form-switch">
          <input type="checkbox" className="form-check-input"  id={`lunchbtn-${row.id}`} checked={row.is_lunch ? true: false} onChange={() => handleDineStatus(row, 'lunch')} />
        </div>
      );
    },
  },{
    dataField: 'actions3',
    text: 'Dinner',
    sort: true,
    formatter: (cell, row) => {
      return (
        <div className="form-check form-switch">
          <input type="checkbox" className="form-check-input" id={`dinerbtn-${row.id}`} checked={row.is_diner ? true: false}  onChange={() => handleDineStatus(row, 'dinner')} />
        </div>
      );
    },
  }, {
    dataField: 'company',
    text: 'Company',
    sort: true
  }, {
    dataField: 'phone',
    text: 'Contact',
    sort: true
  }, {
    dataField: 'location',
    text: 'Location',
    sort: true
  },  {
    dataField: 'actions',
    text: 'Actions',
    formatter: (cell, row) => {
      return (
        <>
          <button onClick={() => setDinerData(row)} className="btn btn-success">
            <i className="fab fa-whatsapp"></i>{" "} Issue Token
          </button>
        </>
      );
    },
  }];

  const issueToken = async(_number) => {
    const obj = {
      "diner": dinerData?.code,
      "_number": _number,
      "is_guest": 1
    }
    const res = await post(`${QR_CODE}`, obj);
    if(res?.success) {
      setDinerData(null)
    }
  }

  const fetchData = async () => {
    setLoading(true);
    const res = await get(`${GUEST}`);
    if(res?.success) {
      setGuestData(res?.body)
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const addRecord = async (data) => {
    setSubmitBtnActive(true);
    const res = await post(GUEST, data);
    if(res.success){
      fetchData()
      setFormData(formObj)
      setSubmitBtnActive(false);
      setModalOpen(false);
    }
  }

  const editRecord = async (data) => {
    Swal.fire({
      title: "Currently Not Allowed!",
      text: "",
      icon: "warning"
    });
  }

  const removeRecord = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      preConfirm: async () => {
        try {
          const res = await del(`${EMPLOYEE}/${data.code}`);
          if (res.body === 1) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
          } else {
            throw new Error('Delete failed');
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'There was a problem deleting your file.',
            'error'
          );
        }
      }
    });
  };
  
  const handleAddGuest = async () => {
    const res = await post(GUEST, guestData);
    if(res.success){
      setModalOpen(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Guests | Advance Dental Export Canteen Management</title>
        </MetaTags>
        <Container fluid>
          <DatatableTables title={"Guests"} columns={columns} rows={guestData} isAdd={true} addBtnLabel={"Add Guest"} callback={() => setModalOpen(true)} />
          
          <Modal
            isOpen={modalOpen}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="modal-title">Guest</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleAddGuest}><span aria-hidden="true">×</span></button>
            </div>
            <Form
              onSubmit={(v) => {
                v.preventDefault();
                addRecord(
                  {...formData,
                    firstname: v.target.firstname.value,
                    lastname: v.target.lastname.value,
                    email: v.target.email.value,
                    phone: v.target.phone.value,
                    company: v.target.company.value,
                    purpose: v.target.purpose.value,
                    address: v.target.address.value,
                    location: v.target.location.value,
                    canteen: v.target.canteen.value,
                    remark: v.target.remark.value,
                  })
              }}
            >
              <ModalBody>
                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="firstname" className="form-label imp">First Name</label>
                    <input name="firstname" id="firstname" placeholder="Enter first name" required type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="lastname" className="form-label imp">Last Name</label>
                    <input name="lastname" id="lastname" placeholder="Enter last name" required type="text" className="form-control" />
                  </div>                  
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input name="email" id="email" placeholder="Enter Email" type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="phone" className="form-label imp">Mobile</label>
                    <input name="phone" id="phone" placeholder="Enter Mobile" required type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="company" className="form-label">Company</label>
                    <input name="company" id="company" placeholder="Enter Company" required type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="purpose" className="form-label imp">Visit Purpose</label>
                    <input name="purpose" id="purpose" placeholder="Enter Purpose" required type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="address" className="form-label imp">Address</label>
                    <input name="address" id="address" placeholder="Enter Address" required type="text" className="form-control" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="location" className="form-label imp">Location</label>
                    <input name="location" id="location" placeholder="Please Select Location" required type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="canteen" className="form-label imp">Canteen</label>
                    <input name="canteen" id="canteen" placeholder="Please Select Canteen" required type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="remark" className="form-label">remark</label>
                    <input name="remark" id="remark" placeholder="Please Select Canteen" required type="text" className="form-control" />
                  </div>
                </div>
              </ModalBody>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => setModalOpen(false)}>Close</button>
                <button type="submit" className="btn btn-primary" disabled={submitBtnActive}>Submit</button>
              </div>
            </Form>
          </Modal>


          {/* Whastapp Numner */}
          <Modal
            isOpen={dinerData ? true: false}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="modal-title">Issue Dine Token</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setDinerData(null)}><span aria-hidden="true">×</span></button>
            </div>
            <Form
              onSubmit={(v) => {
                v.preventDefault();
                issueToken(v.target.wp_num.value)
              }}
            >
              <ModalBody>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="wp_num" className="form-label imp">Please Enter Mobile Number</label>
                    <input name="wp_num" id="wp_num" placeholder="Please Enter Mobile Number" required type="text" className="form-control" />
                  </div>
                  <div className="mt-2 col-12">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Diner Name</td>
                          <td>{dinerData?.firstname} {dinerData?.lastname}</td>
                        </tr>
                        <tr>
                          <td>Diner Name</td>
                          <td>{dinerData?.code}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ModalBody>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => setDinerData(null)}>Close</button>
                <button type="submit" className="btn btn-primary" >Submit</button>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Guests;

