import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import moment from "moment"
import { get } from "helpers/api_helper"
import { DASHBOARD_MONTHLY_CHART_DATA } from "helpers/url_helper"

const Apaexlinecolumn = ({data}) => {
  const noOfDays = moment().daysInMonth();

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const _call = async () => {
      const res = await get(DASHBOARD_MONTHLY_CHART_DATA);
      if(res.success){
        setChartData(res.body);
      }
    };
    _call();
  },[])

  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#1D907C", "#21A6BC", "#5A5958"],
    xaxis: {
      categories:  Array.from({ length: noOfDays }, (_, i) => i + 1),
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    grid: {
      borderColor: "#1D907C",
    },
    fill: {
      opacity: 1,
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$ " + val + " thousands"
    //     },
    //   },
    // },
  }

  return (
    <ReactApexChart options={options} series={chartData} type="bar" height={350} />
  )
}

Apaexlinecolumn.propTypes = {
  data: PropTypes.any,
}

export default Apaexlinecolumn
