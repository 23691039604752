import React from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, CSVExport }from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import moment from "moment";

const DatatableTables = ({title, columns=[], rows=[], csv_filename=`${moment().format('YYYY-MM-DD')}`, addBtnLabel='', isAdd=false, callback = ()=>{}}) => {
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: 'All', value: (rows).length }
  ];
  
  const pageOptions = {
    sizePerPage: 10,
    totalSize: rows.length, // replace later with size(customers),
    custom: true,
    sizePerPageList
  }



  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField='id'
                columns={columns}
                data={rows}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField='id'
                    columns={columns}
                    data={rows}
                    search
                    exportCSV = {{
                      fileName:`${csv_filename}.csv`
                    }}
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="6">
                            <h4 className="h4">{title}</h4>
                          </Col>
                          <Col md="6">
                            <div className="d-flex justify-content-end">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                              <div>
                                <ExportCSVButton 
                                  {...toolkitProps.csvProps} 
                                  className="btn btn-success me-2">
                                  <i className="far fa-file-excel" />
                                </ExportCSVButton>
                              </div>
                              <div>
                                {
                                  isAdd && 
                                  <Button className="btn btn-success" onClick={callback}><i className="fas fa-plus"></i> {addBtnLabel}</Button>
                                }
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />

                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )
                }</PaginationProvider>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

DatatableTables.propTypes = {
  title: PropTypes.any,
  columns: PropTypes.any,
  rows: PropTypes.any,
  addBtnLabel: PropTypes.any,
  callback: PropTypes.any,
  isAdd: PropTypes.any,
  csv_filename: PropTypes.any,
}
export default DatatableTables
