import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser } from "../../store/actions"
import logo from "assets/images/logo-dark.png"

const Login = props => {
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(window.location.search);
  const [isAutoLogin, setIsAutoLogin] = useState(false);

  useEffect(() => {
    setIsAutoLogin(queryParams.get('autologin'));
  },[])

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser({...values, access_code: 'C001'}, props.history))
  }
  
  useEffect(() => {
    if(isAutoLogin){
      const user = {
        email: queryParams.get('username'),
        password: queryParams.get('password')
      }
      dispatch(loginUser({...user}, props.history))
    }
  
  }, [isAutoLogin])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Advance Dental Export Canteen Management</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-4 pb-0">
                        <img src={logo} alt="" height={56} style={{left: '-9px', position: 'relative'}}/>
                        <h5 className="pt-2 text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Manage Canteen.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                    
                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          value=""
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">Log In</button>
                      </div>

                      <div className="mt-4 text-end">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Canteen Management Systems. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Catchysystem Technologies Pvt. Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
