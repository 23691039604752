import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  CardBody,
  Container,
  Row,
  Card,
  Col
} from "reactstrap"
import { DASHBOARD_MAIN_CARD } from 'helpers/url_helper'
import{ get } from 'helpers/api_helper'
import logo from "../../assets/images/canteen_logo.png"
import Apaexlinecolumn from "./Charts/columnChart";
import moment from "moment";

const Dashboard = () => {
  const [panelData, setPanelData] = useState(null);

  useEffect(() => {
    const fnD = async () => {
      const res = await get(DASHBOARD_MAIN_CARD);
      if(res.success){
        setPanelData(res?.body)
      }
    } 

    fnD();
  }, [])
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Advance Dental Export Canteen Management</title>
        </MetaTags>
        <Container fluid>
          <h4>Dashboard</h4>

          <Row>
            <Col md={8}>
              <Row>
                <div className="card">
                  <div className="card-body">
                      <div className="row">
                        <div className="col-lg-8">
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <img src={logo} alt="" className="avatar-md rounded-circle img-thumbnail"/>
                              </div>
                              <div className="flex-grow-1 align-self-center">
                                  <div className="text-muted">
                                    <p className="mb-2">Welcome to Canteen Management Panel</p>
                                    <h5 className="mb-1">Canteen</h5>
                                    <p className="mb-0">Surat</p>
                                  </div>
                              </div>
                            </div>
                        </div>
                        <div className="align-self-center col-lg-4">
                            <div className="text-lg-center mt-4 mt-lg-0">
                              <div className="row">
                                  <div className="col-4">
                                    <div>
                                        <p className="text-muted text-truncate mb-2">Total Diners</p>
                                        <h5 className="mb-0">{panelData?.total || 0}</h5>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div>
                                        <p className="text-muted text-truncate mb-2">Employees</p>
                                        <h5 className="mb-0">{panelData?.employees || 0}</h5>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div>
                                        <p className="text-muted text-truncate mb-2">Guests</p>
                                        <h5 className="mb-0">{panelData?.guests || 0}</h5>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <Card>
                  <CardBody>
                    <h5>{moment().format('MMMM')} Tokens</h5>
                    <Apaexlinecolumn data={[]}/>
                  </CardBody>
                </Card>
              </Row>
            </Col>

            <Col md={4}>
              <Card>
                <CardBody>
                  <h5>Today`s Tokens</h5>
                  <Row>
                    <Col>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td></td>
                            <th>Employees</th>
                            <th>Guests</th>
                          </tr>
                          <tr>
                            <td>LUNCH</td>
                            <th>{panelData?.dailyToken?.lunch?.employee}</th>
                            <th>{panelData?.dailyToken?.lunch?.guest}</th>
                          </tr>
                          <tr>
                            <td>DINNER</td>
                            <th>{panelData?.dailyToken?.dinner?.employee}</th>
                            <th>{panelData?.dailyToken?.dinner?.guest}</th>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;
