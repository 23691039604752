/* eslint-disable react/display-name */
import moment from "moment";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Container
} from "reactstrap"
import { post } from "helpers/api_helper"


function generateDeviceId() {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const languages = navigator.languages.join('-');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a simple hash from collected properties
  const deviceId = btoa(`${userAgent}-${platform}-${languages}-${timezone}`);
  return deviceId;
}


const DineAppleAccess = () => {
  const [dinnerData, setDinnerData] = useState(false);
  const [dinnername, setDinnername] = useState(false);

  useEffect(() => {
    const isLogin = localStorage.getItem('apple');
    if (!isLogin) {
      window.location.href='/dine'
    }else{
      const data = JSON.parse(isLogin);
      setDinnerData(data)
      setDinnername(data?.Employees[0])
    }
  }, [])

  const getQr = ()=> {
    const date = moment().format('YYYY-MM-DD')
    const time = moment().format('HH:mm:ss')
    console.log(date, time)
    const obj = {
      id: dinnername?.code,
      date,
      time
    }
    return `https://api.qrserver.com/v1/create-qr-code/?size=150&data=${JSON.stringify(obj)}`
  }
 
  return (
    <React.Fragment>
        <MetaTags>
          <title>Dinner | Advance Dental Export Canteen Management</title>
        </MetaTags>
        <div className="bg-primary d-flex" style={{alignItems: 'center', width: '100%', height: '60px', justifyContent: 'space-between'}}>
          <h3 className="text-white ms-2 mt-2">Home</h3>
          <div>
            <button className="btn btn-primary" onClick={async()=> {
              const userdata = localStorage.getItem('appleLogin');
              if(userdata){
                try {
                  let obj = atob(userdata);
                  const res = await post('/auth/employee', JSON.parse(obj));
                  if(res.success){
                    if(res?.body != "invalid token" && res?.body != null){
                      localStorage.setItem('apple', JSON.stringify(res.body))
                      localStorage.setItem('appleLogin', btoa(obj))
                      setTimeout(() => {
                        window.location.href='/access-dine'
                      }, 500)
                    }else{
                      localStorage.clear();
                      window.location.href='/dine'
                    }
                  }
                }catch(e){
                  localStorage.clear();
                  window.location.href = '/dine';
                }
              }else{
                localStorage.clear();
                window.location.href='/dine'
              }
              
            }}><i className="mdi mdi-refresh" style={{fontSize: '22px'}} /></button>
            <button className="btn btn-primary text-danger" onClick={() => {
              localStorage.clear();
              window.location.href = '/dine';
            }}><i className="mdi mdi-logout-variant" style={{fontSize: '22px'}} /></button>
          </div>
        </div>
        <Container>
            <div className="text-center">
              <img src={getQr()} style={{width: '320px'}} className="mt-5"/>
              
              <br />
              <br />
              
              <Card style={{width: '80%', margin: '0 auto'}}>
                <CardBody>
                  <b>{dinnername.firstname} {" "} {dinnername.lastname}</b> <br />
                  <b>{dinnername.code}</b><br /><br />

                  <div className="" style={{margin: '0 auto'}}>
                    <span className="me-3"><b style={{fontSize: '16px'}}>Lunch</b> <i className={`${dinnerData?.lunch? 'fas fa-check-circle text-success': 'mdi mdi-close-circle text-danger'}`} style={{fontSize: '18px'}} /></span>
                    <span><b style={{fontSize: '16px'}}>Dinenr</b> <i className={`${dinnerData?.dinner? 'fas fa-check-circle text-success': 'mdi mdi-close-circle text-danger'}`} style={{fontSize: '18px'}} /></span>
                  </div>
                </CardBody>
              </Card>
            </div>
        </Container>
    </React.Fragment>
  )
}

export default DineAppleAccess;
