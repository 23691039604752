export const LOGIN_URL = "/auth/login"
export const LOCATION = "/locations"
export const CANTEEN = "/canteens"
export const PANELUSERS = "/panel/user"
export const EMPLOYEE = "/employees"
export const QR_CODE = "/dine/qr"
export const GUEST = "/guests"
export const DINER_STATUS = "/dine/status"
export const DASHBOARD_MAIN_CARD = "/dashboard"
export const DASHBOARD_MONTHLY_CHART_DATA = "/dashboard/monthly-data"
export const REPORTS_URL = "/reports"
