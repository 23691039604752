import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { Alert, Row, Col, Container } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logo from "assets/images/logo-dark.png"
import { post } from "helpers/api_helper"

function generateDeviceId() {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const languages = navigator.languages.join('-');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a simple hash from collected properties
  const deviceId = btoa(`${userAgent}-${platform}-${languages}-${timezone}`);
  return deviceId;
}

const DineAppleLogin = props => {
  const [error, setError] = useState(null);
  useEffect(() => {
    const isLogin = localStorage.getItem('apple'); // Check if token exists

    if (isLogin) {
     window.location.href='/access-dine'
    }
  }, [])

  // handleValidSubmit
  const handleValidSubmit = async(event, values) => {
    setError(null)
    const data = {...values, access_code: 'C001', mobile_token:  generateDeviceId()}
    const res = await post('/auth/employee', data);
    if(res.success){
      if(res?.body != "invalid token" && res?.body != null){
        localStorage.setItem('apple', JSON.stringify(res.body))
        localStorage.setItem('appleLogin', btoa(JSON.stringify(data)))
        setTimeout(() => {
          window.location.href='/access-dine'
        }, 500)
      }else{
        setError("Unauthorized")
      }
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Dine - Advance Dental Export Canteen Management</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="text-center text-primary" style={{marginTop: '156px', marginBottom: '32px'}}>
                <img src={logo} alt="" height={82} style={{left: '-9px', position: 'relative'}}/>
              </div>
             { error && <Alert color="danger">{error}</Alert> }
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                
                  <div className="mb-3">
                    <AvField
                      name="access_code"
                      label="Access Code"
                      value="C001"
                      readOnly={true}
                      className="form-control"
                      placeholder="Enter Access code"
                      type="text"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="username"
                      label="Username"
                      value=""
                      className="form-control"
                      placeholder="Enter username"
                      type="text"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password"
                      value=""
                      type="password"
                      required
                      placeholder="Enter Password"
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button className="btn btn-primary btn-block" type="submit">Submit</button>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DineAppleLogin)

DineAppleLogin.propTypes = {
  history: PropTypes.object,
}
